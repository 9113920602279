import { Cell } from "./Cell";
import { ILifeRule } from "./rules/ILifeRule";
import { DeadCellWithThreeNeighborsRule } from "./rules/DeadCellWithThreeNeighborsRule";
import { LiveCellWithFewerThanTwoNeighborsRule } from "./rules/LiveCellWithFewerThanTwoNeighborsRule";
import { LiveCellWithTwoOrThreeNeighborsRule } from "./rules/LiveCellWithTwoOrThreeNeighborsRule";
import { LiveCellWithMoreThanThreeNeighborsRule } from "./rules/LiveCellWithMoreThanThreeNeighborsRule";

export class GameOfLife {
  rows: number;
  columns: number;

  cells: Array<Array<Cell>> = [];
  lifeRules: Array<ILifeRule>;

  constructor(rows: number, columns: number) {
    this.rows = rows;
    this.columns = columns;
    this.lifeRules = [];
    this.createCells();
  }

  private createCells(): void {
    this.lifeRules = [
      new LiveCellWithFewerThanTwoNeighborsRule(),
      new LiveCellWithTwoOrThreeNeighborsRule(),
      new LiveCellWithMoreThanThreeNeighborsRule(),
      new DeadCellWithThreeNeighborsRule()
    ];

    for (let x = 0; x < this.rows; x++) {
      this.cells[x] = [];

      for (let y = 0; y < this.columns; y++)
        this.cells[x][y] = new Cell(this.lifeRules);
    }
  }

  setLivingCell = (x: number, y: number) => (this.cells[x][y].isAlive = true);

  setCellState = (x: number, y: number, isAlive: boolean) =>
    (this.cells[x][y].isAlive = isAlive);

  countLivingNeighbors(x: number, y: number): number {
    const surroundingCellPositions = [
      [x - 1, y - 1],
      [x - 1, y],
      [x - 1, y + 1],
      [x, y - 1],
      [x, y + 1],
      [x + 1, y - 1],
      [x + 1, y],
      [x + 1, y + 1]
    ];

    return surroundingCellPositions.filter(
      p => this.isWithinGrid(p[0], p[1]) && this.cells[p[0]][p[1]].isAlive
    ).length;
  }
  // Read up on this, copy-pasted...
  private isWithinGrid = (row: number, column: number) =>
    row >= 0 && column >= 0 && row < this.rows && column < this.columns;

  computeNextGeneration() {
    const nextGeneration: Array<Array<Cell>> = [];

    for (let x = 0; x < this.rows; x++) {
      nextGeneration[x] = [];

      for (let y = 0; y < this.columns; y++)
        nextGeneration[x][y] = this.cells[x][y].next(
          this.countLivingNeighbors(x, y)
        );
    }

    this.cells = nextGeneration;
  }

  setState(state: Array<Array<boolean>>) {
    for (let x = 0; x < this.rows; x++) {
      for (let y = 0; y < this.columns; y++) {
        this.setCellState(x, y, state[x][y]);
      }
    }
  }

  setRandomState() {
    const bound = 0.3;
    for (let x = 0; x < this.rows; x++) {
      for (let y = 0; y < this.columns; y++) {
        this.setCellState(x, y, Math.random() < bound);
      }
    }
  }
}
