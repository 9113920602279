import React from "react";
import Board from "./Board";
import GameControls from "./GameControls";
import { Cell } from "../game/Cell";
import { GameOfLife } from "../game/GameOfLife";

interface GameProps {
  rows: number;
  columns: number;
}

interface GameState {
  // squares: Array<Array<boolean>>;
  generationNumber: number;
  isAnimating: boolean;
  isReversing: boolean;
  gameOfLife: GameOfLife;
  history: Array<History>;
}

interface History {
  squares: Array<Array<boolean>>;
}

class Game extends React.Component<GameProps, GameState> {
  constructor(props: GameProps) {
    super(props);
    this.state = {
      history: [
        {
          squares: Array<boolean[]>(this.props.rows).fill(
            Array(this.props.columns).fill(false)
          ),
        },
      ],
      generationNumber: 0,
      gameOfLife: new GameOfLife(this.props.rows, this.props.columns),
      isAnimating: false,
      isReversing: false,
    };
    this.randomizeCells = this.randomizeCells.bind(this);
    this.resetState = this.resetState.bind(this);
    this.animate = this.animate.bind(this);
    this.startAnimation = this.startAnimation.bind(this);
    this.stopAnimation = this.stopAnimation.bind(this);
    this.toggleCellState = this.toggleCellState.bind(this);
    this.goToNextGeneration = this.goToNextGeneration.bind(this);
    this.goToPreviousGeneration = this.goToPreviousGeneration.bind(this);
  }

  animate(starting: boolean) {
    if (!starting && !this.state.isAnimating) return;
    if (this.state.isReversing) {
      if (this.state.generationNumber < 1) {
        this.stopAnimation();
        return;
      }
      this.goToPreviousGeneration();
    } else {
      this.goToNextGeneration();
    }
    setTimeout(() => this.animate(false), 250);
  }

  startAnimation(isReversing?: boolean) {
    console.log("Starting animation");
    this.setState({
      isAnimating: true,
      isReversing: isReversing === undefined ? false : isReversing,
    });
    this.animate(true);
  }

  stopAnimation() {
    console.log("Stopping animation");
    this.setState({
      isAnimating: false,
      isReversing: false,
    });
  }

  goToPreviousGeneration() {
    // console.log("Reverting to the previous generation!");
    const history = this.state.history.slice(0, this.state.generationNumber);
    const previousState = history[history.length - 1];
    const squares = previousState.squares.slice();

    this.state.gameOfLife.setState(squares);
    this.setState({
      history: history,
      generationNumber: history.length - 1,
    });
  }

  goToNextGeneration() {
    // console.log("Advancing to the next generation!");
    const history = this.state.history.slice(
      0,
      this.state.generationNumber + 1
    );
    this.state.gameOfLife.computeNextGeneration();
    const squares = this.cellsToSquares(this.state.gameOfLife.cells);
    this.setState({
      history: history.concat([
        {
          squares: squares,
        },
      ]),
      generationNumber: history.length,
    });
  }

  resetState() {
    const initialHistory = [
      {
        squares: Array<boolean[]>(this.props.rows).fill(
          Array(this.props.columns).fill(false)
        ),
      },
    ];
    this.state.gameOfLife.setState(initialHistory[0].squares);
    this.setState({
      history: initialHistory,
      generationNumber: 0,
    });
  }

  toggleCellState(r: number, c: number) {
    // console.log("Square with coords " + r + "," + c + " was clicked!");
    if (this.state.isAnimating) return;

    const history = this.state.history.slice();
    const current = history[history.length - 1];
    const cells = this.state.gameOfLife.cells;
    cells[r][c].isAlive = !cells[r][c].isAlive;
    const squares = this.cellsToSquares(cells);
    current.squares = squares;

    this.setState({
      history: history,
    });
  }

  randomizeCells() {
    this.state.gameOfLife.setRandomState();
    const history = this.state.history.slice();
    const current = history[history.length - 1];
    const cells = this.state.gameOfLife.cells;
    const squares = this.cellsToSquares(cells);
    current.squares = squares;

    this.setState({
      history: history,
    });
  }

  cellsToSquares(cells: Cell[][]): Array<Array<boolean>> {
    return cells.map(r => {
      return r.map(c => {
        return c.isAlive;
      });
    });
  }

  render() {
    const squares = this.cellsToSquares(this.state.gameOfLife.cells);
    let status = "Generation #" + this.state.generationNumber;

    return (
      <div className="game">
        <div className="game-board">
          <Board
            rows={this.props.rows}
            columns={this.props.columns}
            squares={squares}
            onCellClick={(r: number, c: number) => this.toggleCellState(r, c)}
          />
        </div>
        <div className="game-info">
          <div>{status}</div>

          {/* <ol>{moves}</ol> */}
        </div>
        <GameControls
          generationNumber={this.state.generationNumber}
          isAnimating={this.state.isAnimating}
          onPreviousGenerationClick={this.goToPreviousGeneration}
          onNextGenerationClick={this.goToNextGeneration}
          onReverseClick={() => this.startAnimation(true)}
          onStartClick={this.startAnimation}
          onStopClick={this.stopAnimation}
          onResetClick={this.resetState}
          onRandomizeClick={this.randomizeCells}
        />
      </div>
    );
  }
}

export default Game;
