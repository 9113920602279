import { ILifeRule } from "./rules/ILifeRule";

export class Cell {
  lifeRules: Array<ILifeRule>;
  isAlive: boolean;

  constructor(lifeRules: Array<ILifeRule>, isAlive: boolean = false) {
    this.lifeRules = lifeRules;
    this.isAlive = isAlive;
  }

  next(numberOfNeighbors: number): Cell {
    const shouldLive: boolean = this.lifeRules
      .filter(r => r.canHandle(this.isAlive))
      .every(r => r.shouldLive(numberOfNeighbors));
    return new Cell(this.lifeRules, shouldLive);
  }
}
