import React from "react";
import { Button, Grid, Fab } from "@material-ui/core";
import Stop from "@material-ui/icons/Stop";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

interface GameControlProps {
  // TODO: put theme here and pass down?
  generationNumber: number;
  isAnimating: boolean;
  onPreviousGenerationClick: () => void;
  onNextGenerationClick: () => void;
  onReverseClick: () => void;
  onStartClick: () => void;
  onStopClick: () => void;
  onResetClick: () => void;
  onRandomizeClick: () => void;
}

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      // main: "#61dbfb"
      main: "#ff9800",
    },
  },
});

class GameControls extends React.Component<GameControlProps> {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className="control-strip">
            <Grid container justify="center" alignItems="center" spacing={8}>
              {/* <Grid item xs> */}
              {/* <Button */}
              {/* id="prev-button" */}
              {/* disabled={ */}
              {/* this.props.isAnimating || this.props.generationNumber < 1 */}
              {/* } */}
              {/* variant="contained" */}
              {/* color="primary" */}
              {/* // size="large" */}
              {/* onClick={() => this.props.onPreviousGenerationClick()} */}
              {/* className="button-history" */}
              {/* > */}
              {/* PREV */}
              {/* </Button> */}
              {/* </Grid> */}
              <Grid item xs lg={2}>
                <Fab
                  id="reverse-button"
                  color="primary"
                  disabled={
                    this.props.isAnimating || this.props.generationNumber < 1
                  }
                  onClick={() => this.props.onReverseClick()}
                >
                  <PlayArrow className="icon-flipped" />
                </Fab>
              </Grid>
              <Grid item xs lg={2}>
                <Fab
                  id="stop-button"
                  color="secondary"
                  disabled={!this.props.isAnimating}
                  onClick={() => this.props.onStopClick()}
                >
                  <Stop />
                </Fab>
              </Grid>
              <Grid item xs lg={2}>
                <Fab
                  id="start-button"
                  color="primary"
                  disabled={this.props.isAnimating}
                  onClick={() => this.props.onStartClick()}
                >
                  <PlayArrow />
                </Fab>
              </Grid>
              {/* <Grid item xs> */}
              {/* <Button */}
              {/* id="next-button" */}
              {/* disabled={this.props.isAnimating} */}
              {/* variant="contained" */}
              {/* color="primary" */}
              {/* // size="large" */}
              {/* onClick={() => this.props.onNextGenerationClick()} */}
              {/* className="button-history" */}
              {/* > */}
              {/* NEXT */}
              {/* </Button> */}
              {/* </Grid> */}
            </Grid>
          </div>
          <div className="control-strip">
            <Grid justify="center" container spacing={8}>
              <Grid item xs lg={3}>
                <Button
                  id="reset-button"
                  disabled={this.props.isAnimating}
                  variant="contained"
                  color="secondary"
                  // size="large"
                  onClick={() => this.props.onResetClick()}
                  className="button-history"
                >
                  RESET
                </Button>
              </Grid>
              <Grid item xs lg={3}>
                <Button
                  id="randomize-button"
                  disabled={this.props.isAnimating}
                  variant="contained"
                  color="secondary"
                  // size="large"
                  onClick={() => this.props.onRandomizeClick()}
                  className="button-history"
                >
                  RANDOMIZE
                </Button>
              </Grid>
            </Grid>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default GameControls;
