import React from "react";
import Row from "./Row";

interface BoardProps {
  rows: number;
  columns: number;
  squares: Array<Array<boolean>>;
  onCellClick: (r: number, c: number) => void;
}

class Board extends React.Component<BoardProps> {
  renderRow = (r: number) => {
    return (
      <Row
        key={r}
        columns={this.props.columns}
        squares={this.props.squares[r]}
        onCellClick={(c: number) => this.props.onCellClick(r, c)}
      />
    );
  };

  createRows = (r: number, c: number) => {
    let rows = [];

    for (let i = 0; i < this.props.rows; i++) {
      rows.push(this.renderRow(i));
    }

    return rows;
  };

  render() {
    return <div>{this.createRows(this.props.rows, this.props.columns)}</div>;
  }
}

export default Board;
