import React from "react";

interface SquareProps {
  isAlive: boolean;
  column: number;
  onClick: (c: number) => void;
}

const Square: React.FC<SquareProps> = props => {
  return (
    <button
      className={props.isAlive ? "square square-alive" : "square square-dead"}
      onClick={() => {
        // console.log(
        //   "I am a square and I was clicked! I am " +
        //     (props.isAlive ? "alive" : "dead")
        // );
        props.onClick(props.column);
      }}
    />
  );
};

export default Square;
