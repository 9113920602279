import React from "react";
import Square from "./Square";

interface RowProps {
  columns: number;
  squares: Array<boolean>;
  onCellClick: (c: number) => void;
}

class Row extends React.Component<RowProps> {
  renderSquare(c: number) {
    return (
      <Square
        key={c}
        isAlive={this.props.squares[c]}
        column={c}
        onClick={(c: number) => this.props.onCellClick(c)}
      />
    );
  }

  createSquares = () => {
    let squares = [];

    for (let i = 0; i < this.props.columns; i++) {
      squares.push(this.renderSquare(i));
    }

    return squares;
  };

  render() {
    return <div className="board-row">{this.createSquares()}</div>;
  }
}

export default Row;
